import React, { useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import { Card, CardBody, CardSubtitle, Col, Container, Modal, ModalHeader, Row } from 'reactstrap'
import Img from 'gatsby-image'

import LayoutJSX from '../layouts/layout-jsx'

const Galerie = ({ data }) => {
  let [isModalOpen, setModalOpen] = useState(false)
  let [embedUrl, setEmbedUrl] = useState(null)
  let [videoTitle, setVideoTitle] = useState(null)
  const closeBtn =
    <button style={{
      WebkitAppearance: 'none',
      backgroundColor: 'transparent',
      border: '0',
      float: 'right',
      fontWeight: '700',
      fontSize: '1.5rem',
      lineHeight: '1',
      color: 'black',
      textShadow: '0 1px 0 #fff',
      opacity: '.5',
      padding: '1rem 1rem',
      margin: '-1rem -1rem -1rem auto'
    }}
    onClick={() => setModalOpen(!isModalOpen)}>
      &times;
    </button>

  return(
    <LayoutJSX
      title="Galerie"
      description="Toutes les photos et vidéos des représentations de l'école Cuu-Long Vo-Dao de Nice."
      location="/galerie">
      <Modal size="lg" isOpen={isModalOpen} toggle={() => setModalOpen(!isModalOpen)} centered >
        <ModalHeader toggle={() => setModalOpen(!isModalOpen)} close={closeBtn}>{videoTitle}</ModalHeader>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            src={embedUrl + '?autoplay=1'}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            className="embed-responsive-item"
            allowFullScreen />
        </div>
      </Modal>
      {data.allDirectusGalerie.group.sort((a,b) => b['fieldValue'] - a['fieldValue']).map(({ edges, fieldValue }) => (
        <React.Fragment key={fieldValue}>
          <Container fluid>
            <h2>
              {fieldValue}
            </h2>
            <Row>
              {edges.map(({node}) => (
                <React.Fragment key={node.id}>
                  <Col
                    xs="12"
                    md="4"
                    style={{ paddingBottom: '2rem' }}>
                    <Card className="text-center" style={{ cursor: 'pointer' }} onClick={() => {
                      setModalOpen(true)
                      setEmbedUrl('https://youtube.com/embed/' + node.fichier_media.embed)
                      setVideoTitle(node.titre_video)
                    }}>
                      <Img className="card-img-top" fluid={node.fichier_media.localFile.childImageSharp.fluid} alt={node.titre_video} />
                      <CardBody>
                        <CardSubtitle>
                          {node.titre_video}
                        </CardSubtitle>
                      </CardBody>
                    </Card>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </Container>
        </React.Fragment>
      ))}
    </LayoutJSX>
  )
}

Galerie.propTypes = {
  data: PropTypes.any
}

export const query = graphql`
{
  allDirectusGalerie(sort: {fields: annee_video, order: DESC}) {
    group(field: annee_video) {
      fieldValue
      edges {
        node {
          id
          titre_video
          annee_video
          fichier_media {
            description
            embed
            localFile {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default Galerie